<template>
  <!-- loading -->
  <v-dialog v-model="show" show-overlay persistent width="400">
    <v-card color="orange" dark>
      <v-card-text>
        Cargando información
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],
};
</script>