<template>
  <div id="dates" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >DÍAS INHÁBILES DE LA EMPRESA</v-row
      >
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerDias()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addNonWorking" id="nuevovacante">
              <button class="breakSearchE botonAmarillo">
                Agregar día inhábil
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="dates"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Días inhábiles por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.motivo }}</td>
                  <td class="text-sm-center">{{ item.fecha.substr(0, 10) }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Editar item-->
    <v-dialog v-model="edit" width="320">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de Fecha</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-5">
              <label for="calendar" style="display: inline-block; width: 100px"
                >Empresa</label
              >
              <select name="empresa" id="empresa" v-model="enterpriseEdit">
                <option disabled value="0" selected>Selecciona Empresa</option>
                <option
                  v-for="enterprise in enterprises"
                  :key="enterprise.id"
                  v-bind:value="enterprise.id"
                >
                  {{ enterprise.razonSocial }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="reason" style="display: inline-block; width: 100px"
                >Motivo del día:</label
              >
              <input
                type="text"
                name="reason"
                id="reason"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="reason"
                required
                maxlength="20"
                @keypress="alfaNumerico($event)"
                style="width: 182px"
              />
            </div>

            <div class="form-group mt-2">
              <label for="job" style="display: inline-block; width: 100px"
                >Oficial</label
              >
              <select name="job" id="job" v-model="oficial">
                <option disabled value="0">¿Es oficial?</option>
                <option value="1">Sí</option>
                <option value="2">No</option>
              </select>
            </div>
            <div class="form-group">
              <label for="date" style="display: inline-block; width: 100px"
                >Fecha</label
              >
              <input
                type="date"
                name="date"
                id="date"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="date"
                required
                maxlength="20"
                style="width: 182px"
              />
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Se deben especificar todos los campos</label
              >
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de vacante</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de día</v-card-title
        >
        <v-card-text id="info">
          <div class="form-group mt-5">
            <label>Id:</label>
            {{ dateSelect.id }}
          </div>
          <div class="form-group">
            <label>Motivo:</label>
            {{ dateSelect.motivo }}
          </div>
          <div class="form-group">
            <label>Fecha:</label>
            {{ dateSelect.fecha.substr(0, 10) }}
          </div>
          <div class="form-group">
            <label>Oficial:</label>
            {{ dateSelect.oficial == true ? "Sí" : "No" }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Motivo",
          align: "center",
          value: "motivo",
        },
        {
          text: "Fecha",
          align: "center",
          value: "fecha",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      dates: [],
      enterprises: [],
      dateSelect: {
        id: 1,
        motivo: "Lunes",
        date: "19/05/2020",
        fecha: "Independencia",
        oficial: "Si",
      },
      enterpriseEdit: 0,
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      date: "",
      reason: "",
      oficial: 0,
      edit: false,
      vacio: false,
      enterprise: localStorage.empresaIdGlobal,
    };
  },
  methods: {
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    editItem(item) {
      console.log("Ingreso a editar" + item.id);
      this.id = item.id;
      this.date = item.fecha.substr(0, 10);
      this.enterpriseEdit = item.empresaId;
      this.reason = item.motivo;
      this.oficial = item.oficial == true ? "1" : "2";
      this.edit = true;
    },
    validacion: function () {
      this.errors = [];
      if (this.enterpriseEdit == "" || this.enterpriseEdit == 0) {
        this.errors.push("Se debe seleccionar la empresa.");
      }
      if (this.reason == "" || this.reason == 0) {
        this.errors.push("Se debe describir el motivo.");
      }
      if (this.oficial == "" || this.oficial == 0) {
        this.errors.push("Se debe especificar si el día es oficial.");
      }
      if (this.date == "" || this.date == " ") {
        this.errors.push("Se debe especificar la fecha.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      console.log("El id para la api es " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/diaInhabil/" + this.id,
          {
            Id: this.id,
            Fecha: this.date,
            EmpresaId: this.enterpriseEdit,
            Motivo: this.reason,
            Oficial: this.oficial == "1" ? true : false,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.date = "";
          this.respuesta = "El día fue actualizado con éxito";
          this.obtenerDias();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.date = "";
            this.respuesta = "El día no fue actualizado";
            this.confirmation = true;
          }
        });
    },
    /*
    temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
    guarda solo el id del dato
    */
    temporal(item) {
      console.log("El id es: " + item.id);
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el día inhábil con fecha " +
        item.fecha.substr(0, 10) +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/diaInhabil/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.show = false;
          this.confirmation = true;
          this.obtenerDias();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      console.log("Ingreso a detalle" + item.id);
      this.dateSelect = item;
      this.dialog = true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$"); // Letras y espacio
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aux() {
      this.edit = false;
      this.oficial = 0;
      this.reason = "";
      this.enterpriseEdit = 0;
      this.date = "";
      this.vacio = false;
    },
    obtenerDias() {
      this.dates = [];
      this.show = true;
      axios
        .get(Server + "/diaInhabil/empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.dates = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerDias();
  },
};
</script>